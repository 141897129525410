<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h3 class="text-center">Voorraad - {{ name }}</h3>
    <hr>
    <Loading v-if="loading" />
    <div v-else>
        <StockPerBuyerTable v-if="stock_per_buyer_per_name" :table_data="stock_per_buyer_per_name" :table_totals="stock_per_buyer_per_name_totals" :table_style="bu" />
        <h4 v-else class="text-center">Geen Data</h4>
    </div>
</div>
</template>

<script>
import StockPerBuyerTable from "@/components/StockPerBuyerTable.vue";
import { msalInstance } from "vue-msal-browser";
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue"

export default {
    props: ["bu"],
    components: { StockPerBuyerTable, Loading },
    data: () => ({
        stock_per_buyer_per_name: null,
        stock_per_buyer_per_name_totals: null,
        name: null,
        loading: true,
    }),
    created() {
        this.name = msalInstance.getAllAccounts()[0].idTokenClaims.name;
        this.getData(this.bu);
    },
    watch:{
        bu(newbu){
            this.loading = true;
            this.getData(newbu);
        }
    },
    methods: {
        getData(bu){
            request(`stock-per-buyer-per-name/${bu}/${this.name}`, "GET").then(({ stock_per_buyer_per_name,  stock_per_buyer_per_name_totals}) => {
                this.stock_per_buyer_per_name = stock_per_buyer_per_name[this.name];
                this.stock_per_buyer_per_name_totals = stock_per_buyer_per_name_totals[this.name];
                this.loading = false;
            })
        }
    }
}

</script>